import { connect } from 'react-redux';
import { footerSelectors } from '@ducks/footer';
import Footer from './Footer';

const mapStateToProps = state => ({
  publisher: state.footer.publisher,
  copyWriteInfo: state.footer.copyWriteInfo,
  newspaper: footerSelectors.newspaperLens(state.footer.newspaper),
  logo: state.footer.logo,
  socialLinks: state.footer.socialLinks,
  socialDisclaimer: state.footer.socialDisclaimer,
  mainLinks: state.footer.mainLinks,
  subLinks: state.footer.subLinks,
  product: state.config.product
});

const footerContainer = connect(mapStateToProps)(Footer);

export default footerContainer;
