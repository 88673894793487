import { connect } from 'react-redux';
import React, { useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { init } from '@schibsted/data-controller';
import cx from 'classnames';
import config from '../../config';
import s from './DataController.scss';

const paths = require('../../pages/paths.json')[config.productId];

const DataController = ({ type, hidden }) => {
  const { pathname: locationPathname } = useLocation();
  const sdkInstance = useRef(null);

  const attachSDK = useCallback(e => {
    if (!sdkInstance.current) {
      sdkInstance.current = init({
        brandName:
          config.productId[0].toUpperCase() + config.productId.slice(1),
        language: config.locale,
        layout: type
      });
    }

    if (sdkInstance.current) {
      sdkInstance.current.render(e, { permanent: type === 'footer' });
    }
  }, []);

  // avoid hiding sticky data controller subheader when automatically redirected from / to /{app}
  const pathname = locationPathname === paths.appBase ? '/' : locationPathname;
  const key = `datacontoller${type}${pathname}`;
  return (
    <div
      className={cx({
        [s.container]: true,
        [s[type]]: true,
        [s.visuallyHidden]: hidden
      })}
      ref={attachSDK}
      key={key}
    />
  );
};

DataController.propTypes = {
  type: PropTypes.oneOf(['subheader', 'footer']).isRequired,
  hidden: PropTypes.bool
};

const mapStateToProps = state => ({
  location: state.router.location,
  hidden: state.user.productImprovementConsentShouldAsk
});

const container = connect(mapStateToProps, null)(DataController);

export default container;
