import React, { Component } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

/**
 * Tapple component
 * @returns {jsx} React markup
 */
class Tappable extends Component {
  state = {
    activeClass: null
  };

  onTouchStart = () => {
    this.addActiveClassName();
  };

  onTouchMove = () => {
    this.removeActiveClassName();
  };

  onTouchEnd = () => {
    this.removeActiveClassName();
  };

  onMouseDown = () => {
    this.addActiveClassName();
  };

  onMouseUp = () => {
    this.removeActiveClassName();
  };

  onMouseOut = () => {
    this.removeActiveClassName();
  };

  onKeyDown = () => {
    this.addActiveClassName();
  };

  onKeyUp = () => {
    this.removeActiveClassName();
  };

  addActiveClassName = () => {
    this.setState({
      activeClass: this.props.activeClassName
    });
  };

  removeActiveClassName = () => {
    this.setState({
      activeClass: null
    });
  };

  render() {
    const {
      component,
      className,
      onClick,
      href,
      children,
      role,
      title,
      isActive,
      type,
      tooltip
    } = this.props;
    const { activeClass } = this.state;
    return React.createElement(
      component || 'button',
      {
        className: cx({
          [className]: className,
          [activeClass]: activeClass
        }),
        title,
        onClick,
        href,
        type,
        onTouchStart: this.onTouchStart,
        onTouchMove: this.onTouchMove,
        onTouchEnd: this.onTouchEnd,
        onMouseDown: this.onMouseDown,
        onMouseUp: this.onMouseUp,
        onMouseOut: this.onMouseOut,
        onKeyDown: this.onKeyDown,
        onKeyUp: this.onKeyUp,
        role,
        'aria-selected': isActive,
        'data-tooltip': tooltip
      },
      children
    );
  }
}

Tappable.propTypes = {
  component: PropTypes.string,
  onClick: PropTypes.func,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  className: PropTypes.string,
  activeClassName: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  type: PropTypes.string,
  tooltip: PropTypes.string
};

export default Tappable;
