import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import Tappable from '@comp/Buttons/Tappable';
import CrossIcon from '@comp/Icons/CrossIcon';
import s from './ClearButton.scss';

const cx = classNames.bind(s);

const ClearButton = ({ onClick, text, disabled, className }) => (
  <Tappable
    onClick={onClick}
    className={cx({
      container: true,
      container__disabled: disabled,
      [className]: className,
      'test__clear-button': true
    })}
    activeClassName={s.container__active}
  >
    <span className={s.inner}>
      <CrossIcon className={s.icon} />
      <span className={s.visuallyHidden}>{text}</span>
    </span>
  </Tappable>
);

ClearButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

export default ClearButton;
