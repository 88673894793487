"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const Publisher = ({ title, name, email }) => {
    let component = (0, jsx_runtime_1.jsx)("span", { children: `${title} ${name}` });
    if (email) {
        component = ((0, jsx_runtime_1.jsxs)("span", { children: [`${title} `, (0, jsx_runtime_1.jsx)("a", { href: `mailto:${email}`, children: name })] }));
    }
    return component;
};
exports.default = Publisher;
