import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import ButtonBase from '@comp/Buttons/ButtonBase';
import s from './ButtonHero.scss';

const cx = classNames.bind(s);

const ButtonHero = ({
  onClick,
  href,
  text,
  className,
  size,
  disabled,
  expand,
  outline,
  type
}) => {
  const conditionalProps = href ? { href } : { onClick };

  return (
    <ButtonBase
      {...conditionalProps}
      className={cx({
        container: true,
        [className]: className,
        container__outline: outline
      })}
      activeClassName={
        outline ? s.container__outlineActive : s.container__active
      }
      size={size}
      disabled={disabled}
      text={text}
      expand={expand}
      type={type}
    />
  );
};

ButtonHero.propTypes = {
  onClick: PropTypes.func,
  href: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOfType([
    PropTypes.oneOf(['small', 'medium', 'large']),
    PropTypes.shape({
      smallBreakPoint: PropTypes.oneOf(['small', 'medium', 'large']),
      mediumBreakPoint: PropTypes.oneOf(['small', 'medium', 'large']),
      largeBreakPoint: PropTypes.oneOf(['small', 'medium', 'large'])
    })
  ]),
  disabled: PropTypes.bool,
  expand: PropTypes.bool,
  outline: PropTypes.bool,
  type: PropTypes.string
};

export default ButtonHero;
