import React, { useState } from 'react';
import { useAppDispatch } from '@src/hooks/useRedux';
import PropTypes from 'prop-types';
import Textarea from '@comp/Forms/Textarea';
import ButtonSecondary from '@comp/Buttons/ButtonSecondary';
import Switch from '@comp/Forms/Switch';
import { campaignActions } from '@ducks/campaign';
import useCustomNavigate from '@src/helpers/navigation';
import s from './CampaignTesting.scss';

const CampaignTesting = () => {
  const [config, setConfig] = useState('');
  const [forceRedesignToggle, setForceRedesignToggle] = useState(false);
  const { navigateToHomePage } = useCustomNavigate();

  const dispatch = useAppDispatch();

  const setActiveCampaign = data => {
    dispatch(
      campaignActions.setActiveCampaign({
        ...JSON.parse(data),
        ...(forceRedesignToggle ? { forceRedesign: forceRedesignToggle } : {})
      })
    );
  };

  const rejectCampaign = () => {
    dispatch(campaignActions.rejectCampaign());
  };

  const handleSubmit = evt => {
    evt.preventDefault();
    if (config.length === 0) {
      rejectCampaign();
    } else {
      setActiveCampaign(config, forceRedesignToggle);
    }
    navigateToHomePage();
  };

  return (
    <form onSubmit={handleSubmit} className={s.container}>
      <h3>Campaign testing tool</h3>
      <Textarea
        input={{
          type: 'text',
          value: config,
          onChange: e => setConfig(e.target.value),
          placeholder: 'Enter campaign JSON configuration'
        }}
        className={s.textarea}
      />
      <div className={s.buttonContainer}>
        <Switch
          id="forceRedesign"
          label="New design"
          checked={forceRedesignToggle}
          onChange={() => {
            setForceRedesignToggle(!forceRedesignToggle);
          }}
        />
        <ButtonSecondary
          size="large"
          text="Replace campaign data"
          type="submit"
          className={s.button}
          expand
        />
      </div>
    </form>
  );
};

CampaignTesting.propTypes = {
  setActiveCampaign: PropTypes.func,
  rejectCampaign: PropTypes.func
};

export default CampaignTesting;
